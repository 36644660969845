<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $tc("color.title") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row class="align-center mt-3">
            <v-col cols="12" sm="5" md="5" class="pr-6">
              <small class="font-weight-bold">Color de fondo</small>
              <input type="color" v-model="backgroundColor" filled rounded />

              <small class="font-weight-bold">Color del texto</small>
              <input
                width="100"
                type="color"
                v-model="fontColor"
                filled
                rounded
              />

              <small class="font-weight-bold">Color de los botones</small>
              <input type="color" v-model="buttonColor" filled rounded />

              <small class="font-weight-bold"
                >Color de la letra de los botones</small
              >
              <input type="color" v-model="buttonFontColor" filled rounded />
            </v-col>
            <v-col
              :style="`backgroundColor : ${backgroundColor}; height: 250px;`"
              cols="12"
              sm="7"
              md="7"
            >
              <v-row class="pa-3">
                <v-col class="align-center" cols="12" sm="12" md="12">
                  <h2 :style="`color : ${fontColor}`">Título de la pregunta</h2>
                </v-col>
                <v-col class="align-center" cols="12" sm="12" md="12">
                  <v-btn
                    :style="`color : ${buttonFontColor}`"
                    rounded
                    width="200"
                    filled
                    :color="buttonColor"
                  >
                    Botón 1
                  </v-btn>
                </v-col>
                <v-col class="align-center" cols="12" sm="12" md="12">
                  <v-btn
                    :style="`color : ${buttonFontColor}`"
                    rounded
                    width="200"
                    filled
                    :color="buttonColor"
                  >
                    Botón 2
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-color-picker v-model="selectedColor" canvas-height="250" /> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-10">
        <v-row align="end" class="mb-3 mx-3">
          <v-btn
            class="flex-grow-1 flex-shrink-0 white--text"
            large
            @click="success"
            rounded
            max-width="200px"
            :elevation="0"
            :minHeight="40"
            color="buttons"
          >
            {{ $tc("button.accept") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "color-picker",
  props: ["colors"],
  data() {
    return {
      backgroundColor: "#9cf5ff",
      fontColor: "#ffffff",
      buttonColor: "#858585",
      buttonFontColor: "#ffffff",
    };
  },
  methods: {
    success() {
      let data = {
        backgroundColor : this.backgroundColor ,
        fontColor : this.fontColor,
        buttonColor : this.buttonColor,
        buttonFontColor : this.buttonFontColor
      }

      this.$emit('success', data)
    }
  },
  mounted() {
    this.backgroundColor = this.colors.backgroundColor
      ? this.colors.backgroundColor
      : "#5f9ea5";
    this.fontColor = this.colors.fontColor ? this.colors.fontColor : "#ffffff";
    this.buttonColor = this.colors.buttonColor
      ? this.colors.buttonColor
      : "#858585";
    this.buttonFontColor = this.colors.buttonFontColor
      ? this.colors.buttonFontColor
      : "#ffffff";
  },
};
</script>

<style scoped>
.main {
  /* background-color: red; */
  width: 100%;
  height: 100%;
}
</style>