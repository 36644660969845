<template>
  <div
    :style="`background-color:  ${editedItem.backgroundColor || '#5f9ea5'}`"
    class="main"
  >
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >

    <div class="actions">
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :loading="loading"
            v-on="on"
            icon
            @click="settingsDialog = true"
          >
            <v-icon class="icons">fa-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("button.save") }}</span>
      </v-tooltip> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :loading="loading"
            v-on="on"
            icon
            @click="selectedItem ? update() : save()"
          >
            <v-icon class="icons">fa-check</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("button.save") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :loading="loading" v-on="on" @click="colorDialog = true" icon>
            <v-icon class="icons">fas fa-palette</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("button.background_color") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn :loading="loading" v-on="on" icon @click="$emit('cancel')">
            <v-icon class="icons">fa-times</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("button.close") }}</span>
      </v-tooltip>
    </div>

    <v-container class="section">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="editedItem.name"
            :label="$tc('dialog.name')"
            clearable
            hide-details
            rounded
            filled
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-textarea
            v-model="editedItem.description"
            :label="$tc('dialog.description')"
            clearable
            rows="2"
            hide-details
            rounded
            filled
          ></v-textarea>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="editedItem.completionText"
            :label="$tc('dialog.completion_message')"
            clearable
            hide-details
            rounded
            filled
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="12" md="12">
          <v-switch
            v-model="editedItem.enableNext"
            :label="$tc('dialog.enable_next')"
            clearable
            hide-details
            rounded
            filled
          ></v-switch>
        </v-col> -->
      </v-row>
    </v-container>

    <draggable
      class="mt-5"
      v-bind="dragOptions"
      ghost-class="ghost"
      v-model="questions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" name="flip-list">
        <question
          v-for="(question, index) of questions"
          :key="question.id"
          @delete="deleteQuestion(index)"
          @add="addQuestion(index)"
          @activate="activateQuestion(index)"
          :question="question"
          :number="index + 1"
          :loading="loading"
        />
      </transition-group>
    </draggable>

    <v-dialog max-width="900px" v-if="colorDialog" v-model="colorDialog">
      <color-picker
        @cancel="colorDialog = false"
        @success="handleColors"
        :colors="colors"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { mapState } from "vuex";
import question from "./question";
import draggable from "vuedraggable";
import colorPicker from "@/components/color-picker";

export default {
  name: "add-form",
  props: ["selectedItem", "surveyId"],
  components: {
    question,
    draggable,
    colorPicker,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: false,
      // defaultImage: require("@/assets/wink-placeholder.png"),
      drag: true,
      colorDialog: false,
      editedItem: {
        enableNext: false,
      },
      // backgroundColor: "#06d6ee",
      // fontColor: "#ffffff",
      // buttonColor: "#858585",
      // buttonFontColor: "#ffffff",
      // file: "",
      // fileSrc: "",
      name: "",
      description: "",
      deletedQuestions: [],
      settingsDialog: false,
      questions: [
        {
          id: this.randomId(),
          type: "radio",
          description: "",
          required: false,
          hint: "",
          active: true,
          answers: [
            { id: this.randomId(), text: "" },
            { id: this.randomId(), text: "" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),

    colors() {
      return {
        backgroundColor: this.editedItem.backgroundColor,
        fontColor: this.editedItem.fontColor,
        buttonFontColor: this.editedItem.buttonFontColor,
        buttonColor: this.editedItem.buttonColor,
      };
    },

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    sortedArray: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return this.questions.sort(compare);
    },
  },

  methods: {
    activateQuestion(position) {
      this.questions.forEach((item, i) => {
        item.active = i == position ? true : false;
      });
    },

    randomId() {
      return `${Date.now()}${
        Math.floor(Math.random() * (10000000000000 - 1000000)) + 1000000
      }`;
    },

    // uploadImage(docId) {
    //   return new Promise((resolve, reject) => {
    //     let extension = this.file.type.split("/").pop();
    //     let dataName = `${Math.round(
    //       Math.random() * (10000000000000 - 100000000) + 100000000
    //     )}_${Math.round(
    //       Math.random() * (10000000000000 - 100000000) + 100000000
    //     )}.${extension}`;

    //     var uploadTask = fb
    //       .app()
    //       .storage("gs://beanage-images")
    //       .ref()
    //       .child(`forms/${this.groupId}/${docId}/${dataName}`)
    //       .put(this.file);

    //     uploadTask.on(
    //       "state_changed",
    //       (snapshot) => {
    //         this.progress =
    //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       },
    //       (error) => {
    //         this.loading = false;
    //         this.snackbarText = this.$tc("messages.unknown");
    //         this.snackbar = true;
    //       },
    //       () => {
    //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //           resolve(downloadURL);
    //         });
    //       }
    //     );
    //   });
    // },

    async save() {
      if (this.editedItem.name) {
        let formReference = db.collection("forms").doc();
        this.loading = true;
        let payload = {
          name: this.editedItem.name,
          description: this.editedItem.description
            ? this.editedItem.description
            : "",
          createdAt: new Date(),
          createdBy: this.user.uid,
          groupId: this.groupId,
          deleted: false,
          active: true,
          availableFrom: new Date(),
          backgroundColor: this.editedItem.backgroundColor,
          fontColor: this.editedItem.fontColor,
          buttonColor: this.editedItem.buttonColor,
          buttonFontColor: this.editedItem.buttonFontColor,
          numberOfQuestions: this.questions.length ? this.questions.length : 0,
          enableNext: this.editedItem.enableNext
            ? this.editedItem.enableNext
            : this.editedItem.enableNext,
          completionText: this.editedItem.completionText
            ? this.editedItem.completionText
            : "",
        };

        //saving form
        await db.collection("forms").doc(formReference.id).set(payload);

        //saving each question
        for (let i = 0; i < this.questions.length; i++) {
          const question = this.questions[i];
          if (question.description) {
            const answers = question.answers.filter((ans) => ans.text);

            await db
              .collection("forms")
              .doc(formReference.id)
              .collection("questions")
              .add({
                type: question.type,
                position: i,
                description: question.description,
                required: question.required,
                hint: question.hint ? question.hint : "",
                answers: answers.length ? answers : [],
                groupId: this.groupId,
                createdBy: this.user.uid,
                createdAt: new Date(),
                deleted: false,
                active: true,
              });
          }
        }

        //uploading picture
        // let imageURL = this.file
        //   ? await this.uploadImage(formReference.id)
        //   : null;

        this.loading = false;
        this.$emit("success");
      } else {
        this.snackbarText = this.$tc("forms.insert_name");
        this.snackbar = true;
      }
    },

    async update() {
      if (this.editedItem.name) {
        this.loading = true;

        let payload = {
          name: this.editedItem.name,
          description: this.editedItem.description
            ? this.editedItem.description
            : "",
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
          active: true,
          backgroundColor: this.editedItem.backgroundColor,
          fontColor: this.editedItem.fontColor,
          buttonColor: this.editedItem.buttonColor,
          buttonFontColor: this.editedItem.buttonFontColor,
          numberOfQuestions: this.questions.length ? this.questions.length : 0,
          enableNext: this.editedItem.enableNext
            ? this.editedItem.enableNext
            : this.editedItem.enableNext,
          completionText: this.editedItem.completionText
            ? this.editedItem.completionText
            : "",
        };

        //updating form data
        await db.collection("forms").doc(this.surveyId).update(payload);

        //updating and adding new questions
        for (let i = 0; i < this.questions.length; i++) {
          const question = this.questions[i];
          if (question.description) {
            const answers = question.answers.filter((ans) => ans.text);

            let reference = question.isSaved
              ? db
                  .collection("forms")
                  .doc(this.surveyId)
                  .collection("questions")
                  .doc(question.id)
              : db
                  .collection("forms")
                  .doc(this.surveyId)
                  .collection("questions")
                  .doc();

            let payload = {
              type: question.type,
              position: i,
              description: question.description,
              required: question.required,
              hint: question.hint ? question.hint : "",
              answers: answers.length ? answers : [],
              modifiedBy: this.user.uid,
              modifiedAt: new Date(),
              deleted: false,
              active: true,
            };

            if (!question.isSaved) {
              payload.groupId = this.groupId;
              payload.createdBy = this.user.uid;
              payload.createdAt = new Date();
              await reference.set(payload);
            } else {
              await reference.update(payload);
            }
          }
        }

        //remove deleted questions
        for (let i = 0; i < this.deletedQuestions.length; i++) {
          const questionId = this.deletedQuestions[i];
          await db
            .collection("forms")
            .doc(this.surveyId)
            .collection("questions")
            .doc(questionId)
            .update({
              deleted: true,
            });
        }

        let imageURL = this.file
          ? await this.uploadImage(formReference.id)
          : null;
        this.loading = false;
        this.$emit("success");
      } else {
        this.snackbarText = this.$tc("forms.insert_name");
        this.snackbar = true;
      }
    },

    // filePicker() {
    //   document.getElementById("file").click();
    // },

    // selectedFile(e) {
    //   this.file = e;
    //   if (this.file) {
    //     var reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.fileSrc = e.target.result;
    //     };
    //     reader.readAsDataURL(this.file);
    //   }
    // },

    handleColors(palette) {
      this.editedItem.backgroundColor = palette.backgroundColor;
      this.editedItem.fontColor = palette.fontColor;
      this.editedItem.buttonColor = palette.buttonColor;
      this.editedItem.buttonFontColor = palette.buttonFontColor;

      this.colorDialog = false;
    },

    addQuestion(e) {
      let position = e + 1;
      this.questions.insert(position, {
        type: "radio",
        description: "",
        required: false,
        id: this.randomId(),
        hint: "",
        active: false,
        answers: [{ id: this.randomId(), text: "" }],
      });
      this.activateQuestion(position);
    },

    deleteQuestion(position) {
      if (this.questions.length > 1) {
        if (this.questions[position].isSaved)
          this.deletedQuestions.push(this.questions[position].id);
        this.questions.splice(position, 1);
      } else {
        this.questions[0].active = true;
      }
    },
  },
  async mounted() {
    if (this.selectedItem) {
      // this.name = this.selectedItem.name;
      // this.description = this.selectedItem.description;
      // this.backgroundColor = this.selectedItem.backgroundColor;
      // this.fontColor = this.selectedItem.fontColor;
      // this.buttonColor = this.selectedItem.buttonColor;
      // this.buttonFontColor = this.selectedItem.buttonFontColor;
      // this.fileSrc = this.selectedItem.image
      //     ? this.selectedItem.image.original
      //     : "";

      this.editedItem = JSON.parse(JSON.stringify(this.selectedItem));

      this.loading = true;
      let questions = await db
        .collection("forms")
        .doc(this.surveyId)
        .collection("questions")
        .where("deleted", "==", false)
        .get();

      this.questions = questions.docs.map((question, index) => {
        let item = question.data();
        item.id = question.id;
        item.active = index == 0 ? true : false;
        item.isSaved = true;
        return item;
      });

      this.questions = this.sortedArray;
      this.loading = false;
    }

    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item);
    };
  },
};
</script>

<style scoped>
.header {
  height: 10vh;
  background-color: #ffffff;
  width: 100vw;
}

.close {
  float: right;
  position: relative;
  top: 10px;
  right: 10px;
}

.close i {
  color: dimgrey !important;
  font-size: 20px !important;
  padding: 5px;
}

.icons {
  font-size: 30px !important;
  color: gray !important;
}

.section {
  max-width: 900px;
  min-height: 100px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.main {
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
}

.image-container {
  width: 100%;
  margin-top: 15px;
  max-height: 200px;
  position: relative;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

.preview {
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
}

.actions {
  background-color: white;
  display: grid;
  width: 60px;
  min-height: 200px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  right: 40px;
  -webkit-box-shadow: 5px 5px 15px 5px #5f5f5f;
  box-shadow: 5px 5px 15px 5px #5f5f5f;
  z-index: 9999;
}
</style>