<template>
  <v-container
    class="section"
    :style="
      question.active
        ? 'border-left: 10px solid #727373; cursor:move'
        : 'cursor:move'
    "
  >
    <small class="font-weight-bold" v-if="question.active"
      >{{ $tc("forms.number") }} {{ number }}</small
    >
    <v-btn :loading="loading" @click="$emit('delete')" dark icon class="delete">
      <v-icon style="color: #4d4d4d">fas fa-times</v-icon>
    </v-btn>
    <v-row class="mt-3">
      <v-col cols="12" sm="12" md="12">
        <v-text-field
          v-model="question.description"
          :label="$tc('forms.question')"
          @click="$emit('activate')"
          class="mb-4"
          clearable
          hide-details
          rounded
          filled
        ></v-text-field>
      </v-col>
    </v-row>

    <div v-if="question.active && question.type != 'shortAnswer'">
      <small class="ml-5">{{ $tc("forms.answers") }}</small>
      <hr class="horizontal-line mb-5" />
      <v-row
        class="ml-3"
        v-for="(answer, index) of question.answers"
        :key="index"
      >
        <v-col cols="12" sm="11" md="11">
          <v-text-field
            rounded
            filled
            @click="addAnswer(index)"
            v-model="answer.text"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-btn
            :loading="loading"
            @click="removeAnswers(index)"
            dark
            icon
            class="mt-2"
          >
            <v-icon style="color: dimgrey !important; font-size: 20px"
              >fas fa-times</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="question.active" class="actions">
      <v-btn :loading="loading" @click="$emit('add')" color="buttons">{{
        $tc("forms.add_question")
      }}</v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Question",
  props: ["number", "question", "loading"],

  data() {
    return {
      selectedAnswer: null,
    };
  },

  methods: {
    addAnswer(index) {
      let position = index + 1;

      if (
        this.question.answers.length == position ||
        this.question.answers[position].text
      ) {
        this.question.answers.insert(position, {
          id: this.randomId(),
          text: "",
          bgColor: "#ffffff",
          textColor: "#000000",
        });
      }
    },

    randomId() {
      return `${Date.now()}${
        Math.floor(Math.random() * (10000000000000 - 1000000)) + 1000000
      }`;
    },

    removeAnswers(index) {
      if (this.question.answers.length > 1) {
        this.question.answers.splice(index, 1);
      }
    },

    showEmoji(index) {
      console.log(index);
      this.selectedAnswer = index;
    },
  },
  mounted() {
    Array.prototype.insert = function (index, item) {
      this.splice(index, 0, item);
    };
  },
};
</script>


<style scoped>
.delete {
  cursor: pointer;
  float: right;
}

.delete i {
  font-size: 20px !important;
}

.horizontal-line {
  background-color: #b3adada6 !important;
  height: 1px;
  padding: 0;
  margin-left: 20px;
  width: 98%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;
  margin-right: 40px;
  margin-bottom: 10px;
}

.section {
  max-width: 900px;
  min-height: 100px;
  background-color: white;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>