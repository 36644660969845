<template>
  <div>
    <v-snackbar content-class="centered" top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ originalItem ? $tc('dialog.edit') : $tc('dialog.add') }} {{ $tc('categories.search')}}</span
        >
        <v-btn
          style="right: 10px; top: 10px;  border-radius : 30px!important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <div class="image-container">
                <img
                  id="image"
                  :style="
                    !src
                      ? 'border:1px solid #52525242; min-width: 100%; min-height: 235px; max-height: 235px; object-fit:cover;filter: grayscale(100%);'
                      : ''
                  "
                  class="preview"
                  :src="src || defaultImage"
                  alt=""
                />

                <i @click="filePicker" class="fas fa-plus add"></i>
              </div>
              <v-progress-linear
                :value="progress"
                color="progress-bar"
                style="border-radius: 5px"
                class="mt-3"
                height="30"
              >
                <strong>{{ Math.ceil(progress) }}%</strong>
              </v-progress-linear>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="category.name"
                    :label="$tc('dialog.name')"
                    :loading="loading"
                    clearable
                    hide-details
                    rounded
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="category.description"
                    :label="$tc('dialog.description')"
                    :loading="loading"
                    clearable
                    hide-details
                    rows="6"
                    rounded
                    filled
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
         
          <v-row class="pa-0 ma-0">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="originalItem ? update() : save()"
                  color="buttons"
                  >{{$tc('button.save')}}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-file-input
      accept="image/png, image/jpeg"
      show-size
      counter
      :disabled="loading"
      id="file"
      @change="selectedFile"
      label="Seleccionar imagen"
      style="display: none"
      rounded
      filled
    ></v-file-input>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-category",
  props: ["originalItem", "categories"],
  data() {
    return {
      category: {
        name: "",
        description: "",
      },
      defaultImage: require("@/assets/wink-placeholder.png"),
      snackbarText: "",
      snackbar: false,
      loading: false,
      progress: 0,
      src: "",
    };
  },
  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    filePicker() {
      document.getElementById("file").click();
    },
    selectedFile(e) {
      this.file = e;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.src = e.target.result;
      };
      reader.readAsDataURL(this.file);
    },
    update() {
      if (this.category.name) {
        this.loading = true;
        let data = {
          name: this.category.name,
          description: this.category.description,
          modifiedAt: new Date(),
          modifiedBy: this.user.uid,
          // type : "category"
        };

        db.collection("categories")
          .doc(this.originalItem[".key"])
          .update(data)
          .then(async (response) => {
            if (this.file) await this.uploadImage(this.originalItem[".key"]);
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText =  this.$tc('messages.unknown');
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc('categories.enter_name');
        this.snackbar = true;
      }
    },
    save() {
      if (this.category.name) {
        this.loading = true;

        let data = {
          name: this.category.name,
          createdAt: new Date(),
          createdBy: this.user.uid,
          description: this.category.description,
          groupId: this.groupId,
          type : "category",
          position : this.categories.length + 1
        };

        db.collection("categories")
          .add(data)
          .then(async (ref) => {
            if (this.file) await this.uploadImage(ref.id);
            this.loading = false;
            this.$emit("success");
          })
          .catch((error) => {
            this.loading = false;
            this.snackbarText = this.$tc('messages.unknown');
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc('categories.enter_name');
        this.snackbar = true;
      }
    },
    uploadImage(docId) {
      return new Promise((resolve, reject) => {
        let extension = this.file.type.split("/").pop();
        let dataName = `${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}_${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}.${extension}`;

        var uploadTask = fb
          .app()
          .storage("gs://beanage-images")
          .ref()
          .child(`categories/${this.groupId}/${docId}/${dataName}`)
          .put(this.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.loading = false;
            this.snackbarText = this.$tc('messages.unknown');
            this.snackbar = true;
          },
          function () {
            resolve("success");
          }
        );
      });
    },
  },
  mounted() {
    if (this.originalItem) {
      this.category = Object.assign({}, this.originalItem);
      this.src = this.category.image ? this.category.image.original : "";
    }

    console.log(this.categories);
  },
};
</script>

<style scoped>
.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  padding-top: 10px;
  object-fit: cover;
}

.image-container {
  position: relative;
  width: 100%;
  margin-top: 5px;
  max-height: 235px;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}
</style>