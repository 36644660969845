<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">
          {{$t('categories.link_screens')}}
        </span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="waiting" cols="12" sm="12" md="12">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <transfer
                :leftSideTitle="`${$t(
                  'categories.directory_screens'
                )}`"
                :rightSideTitle="`${$t('categories.screens_added')}`"
                :loading="loading"
                @changed="usersChanged"
                v-if="!waiting"
                :rightSource="selectedScreens || []"
                :leftSource="screens"
              />
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-5">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn @click="save" :loading="loading" color="buttons">{{
                  $tc("button.save")
                }}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import transfer from "@/components/transfer";

export default {
  name: "link-screens",
  props: ["selectedItem"],
  components: {
    transfer,
  },

  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: true,
      waiting: true,
      screens: [],
      newScreens: [],
      selectedScreens: [],
    };
  },

  computed: {
    ...mapState(["user", "groupId"]),
  },

  methods: {
    usersChanged(data) {
      this.newScreens = data;
    },

    async save() {
      this.loading = true;
      db.collection("forms")
        .doc(this.selectedItem.surveyId)
        .update({
          showIn: this.newScreens.length ? this.newScreens : [],
        })
        .then((response) => {
          this.loading = false;
          this.$emit("success");
        })
        .catch((error) => {
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },
  },
  async mounted() {
    let surveyQuery = await db
      .collection("forms")
      .doc(this.selectedItem.surveyId)
      .get();

    this.selectedScreens = surveyQuery.data().showIn
      ? surveyQuery.data().showIn
      : [];

    db.collection("screens")
      .where("groupId", "==", this.groupId)
      .where("selectedApp", "==", "directories")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let screen = item.data();
          screen[".key"] = item.id;
          this.screens.push(screen);
        });

        this.loading = false;
        this.waiting = false;
      });
  },
};
</script>