<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("categories.title") }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn
            color="buttons"
            class="mr-5"
            @click="showSortDialog"
            :loading="loading"
          >
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("categories.change_position") }}
          </v-btn>

          <v-btn color="buttons" @click="showAddDialog" :loading="loading">
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("categories.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :name="$tc('categories.search')" />
        </v-col> -->
      </v-row>

      <v-row class="ml-1 mt-7">
        <v-col
          v-if="!loading && filteredItems.length == 0"
          class="mt-10"
          style="display: flex; justify-content: center"
          cols="12"
          sm="12"
          md="12"
        >
          <span> {{ $tc("messages.empty_search") }} 😔</span>
        </v-col>

        <v-col
          cols="6"
          sm="3"
          md="3"
          v-for="(item, index) of filteredItems"
          :key="index"
        >
          <card
            @menuClick="handleMenuActions($event, item)"
            :type="item.type"
            :name="item.name"
            :image="item.image ? item.image.original : ''"
            :items="item.type == 'survey' ? surveyMenu : categoryMenu"
            :lazy="item.image && item.image.loading ? item.image.loading : ''"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-if="addDialog" persistent max-width="900px" v-model="addDialog">
      <add-categories
        :originalItem="selectedItem"
        :categories="categories"
        @success="added"
        @cancel="addDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="linkScreensDialog"
      persistent
      max-width="900px"
      v-model="linkScreensDialog"
    >
      <link-survey-screens
        :selectedItem="selectedItem"
        @success="screensLinked"
        @cancel="linkScreensDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="surveyDialog"
      persistent
      max-width="600px"
      v-model="surveyDialog"
    >
      <add-survey @selected="addSurvey" @cancel="surveyDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="viewSurveyDialog"
      persistent
      v-model="viewSurveyDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <view-survey
        :selectedItem="selectedItem"
        :surveyId="selectedItem.surveyId"
        @cancel="viewSurveyDialog = false"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="viewImageDialog"
      v-model="viewImageDialog"
      hide-overlay
      fullscreen
    >
      <view-file
        :url="selectedItem.image.original"
        type="image"
        @cancel="viewImageDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="editSurveyDialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      v-model="editSurveyDialog"
    >
      <edit-survey
        :surveyId="selectedItem.surveyId"
        :selectedItem="selectedItem"
        @cancel="editSurveyDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog
      v-if="sortDialog"
      persistent
      max-width="500px"
      v-model="sortDialog"
    >
      <sort
        :source="categories"
        @updatedItems="handleSortedItems"
        @success="added"
        @cancel="sortDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import addCategories from "./add-categories";
import deleteItem from "@/components/delete";
import filesTable from "@/components/files-table";
import viewFile from "@/components/view-file";
import card from "@/components/card";
import sort from "@/components/sort";
import viewSurvey from "@/views/survey/preview";
import { mapActions, mapState } from "vuex";
import addSurvey from "./add-survey";
import editSurvey from "@/views/survey/add";
import linkSurveyScreens from "./link-surveys-screens";

export default {
  name: "Categories",
  components: {
    addCategories,
    deleteItem,
    filesTable,
    viewFile,
    card,
    sort,
    addSurvey,
    viewSurvey,
    editSurvey,
    linkSurveyScreens,
  },
  data() {
    return {
      categories: [],
      snackbarText: "",
      surveyDialog: false,
      viewSurveyDialog: false,
      editSurveyDialog: false,
      linkScreensDialog: false,
      snackbar: false,
      loading: true,
      addDialog: false,
      subcategoriesDialog: false,
      selectedItem: null,
      deleteDialog: false,
      viewImageDialog: false,
      sortDialog: false,
      surveyMenu: [
        {
          title: this.$tc("table.view_file"),
          icon: "fas fa-eye",
          action: "view",
        },
        { title: this.$tc("table.edit"), icon: "fas fa-edit", action: "edit" },
        {
          title: this.$tc("table.show_survey_in"),
          icon: "fas fa-desktop",
          action: "link_screens",
        },
        {
          title: this.$tc("table.delete"),
          icon: "fas fa-trash",
          action: "delete",
        },
      ],
      categoryMenu: [
        {
          title: this.$tc("table.view_file"),
          icon: "fas fa-eye",
          action: "view",
        },
        { title: this.$tc("table.edit"), icon: "fas fa-edit", action: "edit" },
        {
          title: this.$tc("table.delete"),
          icon: "fas fa-trash",
          action: "delete",
        },
      ],
    };
  },

  computed: {
    ...mapState(["search", "groupId", "user"]),
    filteredItems() {
      let data = [];
      if (!this.search) data = this.categories;
      else {
        data = this.categories.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      return data;
    },
  },
  methods: {
    ...mapActions(["cleanSearch"]),
    sortedArray: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.categories.sort(compare);
    },

    screensLinked() {
      this.linkScreensDialog = false;
      this.snackbarText = this.$t("categories.screens_linked");
      this.snackbar = true;
    },

    handleSuccess() {
      this.addDialog = false;
      this.snackbarText = this.$t("categories.updated");
      this.snackbar = true;
      this.editSurveyDialog = false;
    },

    addSurvey(surveyId) {
      let data = {
        createdAt: new Date(),
        createdBy: this.user.uid,
        type: "survey",
        surveyId,
        groupId: this.groupId,
      };

      db.collection("categories")
        .add(data)
        .then((ref) => {
          this.surveyDialog = false;
          this.snackbarText = this.$tc("forms.added");
          this.snackbar = true;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
        });
    },

    showSortDialog() {
      this.sortDialog = true;
    },

    handleSortedItems(items) {
      for (let i = 0; i < items.length; i++) {
        let category = items[i];
        db.collection("categories").doc(category[".key"]).update({
          position: i,
        });
      }
      this.snackbarText = this.$tc("categories.sorted");
      this.snackbar = true;
      this.sortDialog = false;
      this.getData();
    },

    linkScreens(item) {
      this.selectedItem = item;
      this.linkScreensDialog = true;
    },

    handleMenuActions(action, item) {
      switch (action) {
        case "view":
          this.view(item);
          break;
        case "edit":
          this.edit(item);
          break;
        case "delete":
          this.showDeleteDialog(item);
          break;
        case "link_screens":
          this.linkScreens(item);
          break;
      }
    },
    showAddDialog() {
      this.selectedItem = null;
      this.addDialog = true;
    },
    viewSubcategories(item) {
      this.selectedItem = item;
      this.subcategoriesDialog = true;
    },
    view(item) {
      this.selectedItem = item;

      if (item.type == "survey") {
        this.viewSurveyDialog = true;
      } else {
        this.viewImageDialog = true;
      }
    },
    viewSurvey(item) {
      this.selectedItem = item;
      this.viewSurveyDialog = true;
    },
    edit(item) {
      this.selectedItem = item;
      if (item.type == "survey") {
        this.editSurveyDialog = true;
      } else {
        this.addDialog = true;
      }
    },
    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("categories")
        .doc(this.selectedItem[".key"])
        .delete()
        .then((response) => {
          this.snackbarText = this.$tc("categories.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
          this.getData();
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
    added() {
      this.addDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$tc("categories.updated")
        : this.$tc("categories.added");
      this.snackbar = true;
      this.getData();
    },
    async getData() {
      db.collection("categories")
        .where("groupId", "==", this.groupId)
        .get()
        .then(async (response) => {
          this.categories = [];

          for (let i = 0; i < response.docs.length; i++) {
            const category = response.docs[i].data();
            category[".key"] = response.docs[i].id;

            switch (category.type) {
              case "survey":
                let surveyQuery = await db
                  .collection("forms")
                  .doc(category.surveyId)
                  .get();

                let survey = surveyQuery.data();

                category.name = survey.name;
                category.description = survey.description;
                category.image = survey.image ? survey.image : {};
                category.backgroundColor = survey.backgroundColor;
                this.categories.push(category);

                break;

              case "category":
                this.categories.push(category);
                break;

              default:
                this.categories.push(category);
                break;
            }
          }

          this.categories = this.sortedArray();
          this.loading = false;
        });
    },
  },
  mounted() {
    this.cleanSearch();
    this.getData();
  },
};
</script>


<style>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}
</style>