<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">{{ $t("categories.select_survey") }}</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-3">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                :label="$t('agencies.survey.select_survey')"
                v-model="survey"
                item-text="name"
                :items="surveys"
                item-value="id"
                :loading="loading"
                aria-autocomplete="false"
                autocomplete="off"
                hide-details
                filled
                rounded
                :allow-overflow="false"
                clearable
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn :loading="loading" @click="selected" color="buttons">{{
                  $tc("button.save")
                }}</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";

export default {
  name: "add-survey",
  data() {
    return {
      snackbarText: "",
      defaultImage: require("@/assets/wink-placeholder.png"),
      snackbar: false,
      loading: false,
      surveys: [],
      survey: "",
    };
  },

  computed: {
    ...mapState(["groupId"]),
  },

  methods: {
    selected() {
      if (this.survey) {
        this.loading = true;
        this.$emit("selected", this.survey);
      } else {
        this.snackbarText = "Seleccione una encuesta";
        this.snackbar = true;
      }
    },
    sortedArray: function (data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      return data.sort(compare);
    },
  },
  mounted() {
    db.collection("forms")
      .where("groupId", "==", this.groupId)
      .onSnapshot((response) => {
        this.surveys = [];
        response.forEach((item) => {
          let survey = item.data();
          survey.id = item.id;
          this.surveys.push(survey);
        });
        this.surveys = this.sortedArray(this.surveys);
        this.loading = false;
      });
  },
};
</script>


<style scoped>
.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  /* padding-top: 10px; */
}

.image-container {
  position: relative;
  width: 100%;
  /* margin-top: 5px; */
  max-height: 235px;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}
</style>