<template>
  <div style="background-color: white" class="main">
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >

    <v-btn
      style="border-radius: 60px !important"
      class="close"
      :loading="loading"
      icon
      @click="$emit('cancel')"
    >
      <v-icon style="font-size: 25px !important; color: white" class="icons"
        >fa-times</v-icon
      >
    </v-btn>

    <v-row v-if="!finished">
      <v-col class="mt-5" cols="12" sm="12" md="12">
        <h2 class="text-center">{{ selectedItem.name }}</h2>
      </v-col>
    </v-row>

    <v-row
      :style="`background-color:${selectedItem.backgroundColor}`"
      class="completion-text"
      v-if="finished"
    >
      <v-col cols="12" sm="12" md="12">
        <h1
          :style="`color:${selectedItem.buttonFontColor}`"
          class="text-center"
        >
          {{ selectedItem.completionText }}
        </h1>
      </v-col>
    </v-row>

    <v-row v-if="!loading && !finished">
      <v-col class="main-question" cols="12" sm="12" md="12">
        <div
          class="question"
          :style="`background-color:${selectedItem.backgroundColor}`"
        >
          <div>
            <h2
              :style="`color:${selectedItem.fontColor}`"
              class="mt-10 mb-10 text-center"
            >
              {{
                sortedArray[selectedIndex]
                  ? sortedArray[selectedIndex].description
                  : ""
              }}
            </h2>

            <div
              v-for="(answer, index) of sortedArray[selectedIndex]
                ? sortedArray[selectedIndex].answers
                : []"
              :key="index"
              style="width: 100%"
              class="text-center mt-5"
            >
              <v-btn
                rounded
                :elevation="0"
                :minHeight="56"
                :width="500"
                @click="next"
                :style="`color:${selectedItem.buttonFontColor}`"
                :color="selectedItem.buttonColor"
              >
                {{ answer.text }}
              </v-btn>
            </div>
          </div>
          <p
            :style="`color:${selectedItem.fontColor}`"
            @click="previous"
            class="previous"
          >
            Anterior
          </p>
          <p
            :style="`color:${selectedItem.fontColor}`"
            @click="next"
            class="next"
          >
            Siguiente
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";

export default {
  name: "preview",
  props: ["selectedItem", "surveyId"],

  data() {
    return {
      loading: false,
      questions: [],
      selectedIndex: 0,
      finished: false,
    };
  },

  computed: {
    sortedArray: function () {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return this.questions.sort(compare);
    },
  },

  methods: {
    next() {
      if (this.questions.length > this.selectedIndex + 1) {
        this.selectedIndex = this.selectedIndex + 1;
      } else {
        // console.log("finished");
        this.finished = true;
      }
    },
    previous() {},
  },
  async mounted() {
    this.loading = true;
    // console.log(this.selectedItem[".key"]);
    let questions = await db
      .collection("forms")
      .doc(this.surveyId)
      .collection("questions")
      .where("deleted", "==", false)
      .get();

    this.questions = questions.docs.map((item) => {
      return item.data();
    });

    this.questions = this.sortedArray;
    this.loading = false;
  },
};
</script>

<style scoped>
.close {
  background-color: #5a5a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px !important;
  padding: 30px;
  position: absolute;
  bottom: 40px;
  right: 40px;
}
.section {
  max-width: 900px;
  min-height: 100px;
  background-color: white;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.main {
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  /* justify-content: center;
  align-items: center;
  display: flex; */
}

.preview {
  width: 100%;
  max-height: 200px;
  min-height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

/* .required {
  float: right;
  color: red;
} */

.main-question {
  /* height: 70vh;
  width: 70%;
  border-radius: 5px; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.question {
  width: 70%;
  height: 70vh;
  justify-content: center;
  position: relative;
  /* align-items: center; */
  display: flex;
}

.previous {
  position: absolute;
  bottom: 0;
  left: 10px;
  padding: 10px;
  cursor: pointer;
}

.next {
  position: absolute;
  bottom: 0;
  right: 10px;
  padding: 10px;
  cursor: pointer;
}

.completion-text {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>